import { Capacitor } from "@capacitor/core";
import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { type CbhFeatureFlag } from "@src/appV2/FeatureFlags";
import { type CbhFeatureFlags } from "@src/appV2/FeatureFlags/CbhFeatureFlags";
import { APP_V2_APP_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import HyperTrack, { type OrderStatus } from "hypertrack-sdk-ionic-capacitor";

import { getDeviceGeoLocation } from "../deviceLocation";
import { NonNativePlatformGeolocationError } from "../deviceLocation/NonNativePlatformGeolocationError";
import type { GeoLocation } from "../types";
import { calculateGeoDistanceInMiles } from "./geoDistance";

interface GetGeofenceStatusParams {
  requestUniqueId: string;
  action: "clock-in" | "clock-out";
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
  isHyperTrackEnabled: boolean;
  fallbackGeofenceDistanceInMiles?: number;
  fallbackGeofenceLocation?: GeoLocation;
}

export async function getGeofenceStatus(params: GetGeofenceStatusParams) {
  const {
    requestUniqueId,
    action,
    hyperTrackGeotagEvents,
    isHyperTrackEnabled,
    fallbackGeofenceDistanceInMiles,
    fallbackGeofenceLocation,
  } = params;

  const { isInsideGeofence, error } = isHyperTrackEnabled
    ? await checkIsWorkerInsideFacilityGeofenceHyperTrack({
        orderHandle: requestUniqueId,
        action,
        hyperTrackGeotagEvents,
      })
    : {
        isInsideGeofence: false,
        error: "No geofence check enabled",
      };

  if (!isHyperTrackEnabled || isDefined(error)) {
    if (!isDefined(fallbackGeofenceDistanceInMiles) || !isDefined(fallbackGeofenceLocation)) {
      return {
        isInsideGeofence: false,
        error,
      };
    }

    return await checkIsWorkerInsideFacilityGeofenceFallback({
      fallbackGeofenceLocation,
      fallbackGeofenceDistanceInMiles,
    });
  }

  return {
    isInsideGeofence,
    error,
  };
}

async function checkIsWorkerInsideFacilityGeofenceFallback({
  fallbackGeofenceLocation,
  fallbackGeofenceDistanceInMiles,
}: {
  fallbackGeofenceLocation: GeoLocation;
  fallbackGeofenceDistanceInMiles: number;
}): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  try {
    const { geoLocation } = await getDeviceGeoLocation();
    const distanceInMiles = calculateGeoDistanceInMiles(geoLocation, fallbackGeofenceLocation);

    const result = distanceInMiles <= fallbackGeofenceDistanceInMiles;

    return {
      isInsideGeofence: result,
    };
  } catch (error) {
    return {
      isInsideGeofence: false,
      error,
    };
  }
}

async function checkIsWorkerInsideFacilityGeofenceHyperTrack({
  orderHandle,
  action,
  hyperTrackGeotagEvents,
}: {
  orderHandle: string;
  action: "clock-in" | "clock-out";
  hyperTrackGeotagEvents: CbhFeatureFlags[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS];
}): Promise<{ isInsideGeofence: boolean; error?: unknown }> {
  const { events } = hyperTrackGeotagEvents;
  const shouldGeotagFailures = events === "failures" || events === "all";
  const shouldGeotagSuccesses = events === "all";

  if (!Capacitor.isNativePlatform()) {
    const nonNativePlatformError = new NonNativePlatformGeolocationError();

    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: nonNativePlatformError,
        metadata: {
          orderHandle,
        },
      },
      true
    );

    return { isInsideGeofence: false, error: nonNativePlatformError };
  }

  const activeOrders = await HyperTrack.getOrders();
  const currentOrder = activeOrders.get(orderHandle);
  let orderStatus: OrderStatus = { type: "orderStatusClockIn" };
  if (action === "clock-out") {
    orderStatus = { type: "orderStatusClockOut" };
  }

  const now = new Date().toISOString();

  if (isNullOrUndefined(currentOrder)) {
    const activeOrderHandles = [...activeOrders.keys()];

    const failedToFetchCurrentOrderError = new Error(`Current order not found`);
    logError(
      APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
      {
        error: failedToFetchCurrentOrderError,
        metadata: {
          orderHandle,
          activeOrderHandles,
          numActiveOrders: activeOrderHandles.length,
        },
      },
      true
    );

    if (shouldGeotagFailures) {
      await HyperTrack.addGeotag(orderHandle, orderStatus, {
        activeOrders,
        numActiveOrders: activeOrderHandles.length,
        reason: failedToFetchCurrentOrderError.message,
        isInsideGeofence: false,
        attemptedAt: now,
        stage: action,
      });
    }

    return { isInsideGeofence: false, error: failedToFetchCurrentOrderError };
  }

  const isInsideGeofenceResult = await currentOrder.isInsideGeofence();
  switch (isInsideGeofenceResult.type) {
    case "success": {
      logEvent(APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_SUCCEEDED, {
        metadata: {
          orderHandle,
          activeOrders,
          currentOrder,
        },
      });

      if (isInsideGeofenceResult.value) {
        if (shouldGeotagSuccesses) {
          await HyperTrack.addGeotag(orderHandle, orderStatus, {
            isInsideGeofence: true,
            attemptedAt: now,
            stage: action,
          });
        }
      } else if (shouldGeotagFailures) {
        await HyperTrack.addGeotag(orderHandle, orderStatus, {
          reason: "Too far away",
          isInsideGeofence: false,
          attemptedAt: now,
          stage: action,
        });
      }

      return { isInsideGeofence: isInsideGeofenceResult.value };
    }

    case "failure": {
      const failedToFetchGeofenceStatusError = new Error(
        `Failed to fetch geofence status for current order`
      );
      logError(
        APP_V2_APP_EVENTS.HYPER_TRACK_GEOFENCE_CHECK_FAILED,
        {
          error: failedToFetchGeofenceStatusError,
          metadata: {
            orderHandle,
            activeOrders,
            currentOrder,
          },
        },
        true
      );

      if (shouldGeotagFailures) {
        await HyperTrack.addGeotag(orderHandle, orderStatus, {
          activeOrders,
          reason: failedToFetchGeofenceStatusError.message,
          isInsideGeofence: false,
          attemptedAt: now,
          stage: action,
        });
      }

      return { isInsideGeofence: false, error: failedToFetchGeofenceStatusError };
    }

    default: {
      return { isInsideGeofence: false, error: new Error("Unhandled hypertrack geofence status") };
    }
  }
}
