import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { FEATURE_FLAG_DEFAULT_VALUES } from "@src/appV2/FeatureFlags/constants";
import { useQuery } from "@tanstack/react-query";

import { type GeoLocation } from "../types";
import { getGeofenceStatus } from "../utils/getGeofenceStatus";

export interface UseIsInsideGeofenceParams {
  requestUniqueId: string;
  action: "clock-in" | "clock-out";
  fallbackGeofenceDistanceInMiles?: number;
  fallbackGeofenceLocation?: GeoLocation;
}

export function useLazyIsInsideGeofence(params: UseIsInsideGeofenceParams) {
  const { requestUniqueId, action, fallbackGeofenceDistanceInMiles, fallbackGeofenceLocation } =
    params;

  const hyperTrackGeotagEvents = useCbhFlag(CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.HYPER_TRACK_GEOTAG_EVENTS],
  });

  const isHyperTrackEnabled = useCbhFlag(CbhFeatureFlag.HYPERTRACK_CLOCK_IN_OUT, {
    defaultValue: false,
  });

  const query = useQuery({
    queryKey: ["isInsideOfGeofence", requestUniqueId, action],
    queryFn: async () => {
      return await getGeofenceStatus({
        requestUniqueId,
        action,
        hyperTrackGeotagEvents,
        fallbackGeofenceDistanceInMiles,
        fallbackGeofenceLocation,
        isHyperTrackEnabled,
      });
    },

    enabled: false,
  });

  return query.refetch;
}
